var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"orderdetails"},[_c('Header'),_c('div',{staticClass:"breadcrumb-wrap bg-f br-1"},[_c('div',{staticClass:"overlay bg-black op-9"}),_c('img',{staticClass:"br-shape-1",attrs:{"src":_vm.shape1,"alt":"Image"}}),_c('img',{staticClass:"br-shape-2",attrs:{"src":_vm.shape2,"alt":"Image"}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"breadcrumb-title"},[_c('h2',[_vm._v("Manage Address")]),_c('ul',{staticClass:"breadcrumb-menu list-style"},[_c('li',[_c('router-link',{staticStyle:{"color":"white"},attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',[_vm._v("Manage Address")])])])])])])]),_c('div',{staticClass:"blog-details-wrap ptb-100"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4 col-lg-12 order-xl-1 order-lg-2 order-md-2 order-2"},[_c('div',{staticClass:"sidebar"},[_c('Leftsidebar')],1)]),_c('div',{staticClass:"col-xl-8 col-lg-12 order-xl-2 order-lg-1 order-md-1 order-1"},[_c('div',{staticClass:"bg-white mb-4"},[_vm._m(0),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-md-6 text-end mb-3"},[_c('span',[_c('router-link',{staticClass:"btn style1",attrs:{"to":"/addaddress"}},[_vm._v("Add Address")])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"list-group"},_vm._l((_vm.addresses),function(address,index){return _c('div',{key:index},[_c('div',{class:index % 2 == 0
                              ? `list-group-item list-group-item-action active`
                              : `list-group-item list-group-item-action`,attrs:{"aria-current":index % 2 == 0 ? `true` : `false`}},[_c('div',{staticClass:"d-flex w-100 justify-content-between"},[_c('h5',{staticClass:"mb-1 font-weight-bold"},[_vm._v(" "+_vm._s(address.tag)+" "),(
                                  _vm.userData.default_address_id == address.id
                                )?_c('i',{class:index % 2 == 0
                                    ? `fa fa-star text-warning ml-1`
                                    : `fa fa-star text-warning ml-1`,attrs:{"aria-hidden":"true"}}):_vm._e()]),_c('div',[_c('small',{class:index % 2 == 0
                                    ? `text-dark mr-2`
                                    : `text-dark mr-2`,staticStyle:{"font-size":"20px","position":"relative","top":"14px","right":"14px"},on:{"click":function($event){return _vm.delAddress(address.id)}}},[_c('i',{staticClass:"fa fa-trash-o",attrs:{"aria-hidden":"true"}})]),(
                                  _vm.userData.default_address_id != address.id
                                )?_c('small',{class:index % 2 == 0
                                    ? `text-white`
                                    : `text-primary`,staticStyle:{"font-size":"20px","position":"relative","top":"14px"},on:{"click":function($event){return _vm.defaultAddress(address.id)}}},[_c('i',{staticClass:"fa fa-star-o text-warning",attrs:{"aria-hidden":"true"}})]):_vm._e()])]),_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(address.address)+" ")])])])}),0)])])])])])])])])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"border-bottom pb-4"},[_c('i',{staticClass:"fa fa-pencil",attrs:{"aria-hidden":"true"}}),_vm._v(" Manage Address ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('p',{staticClass:"heading-text"},[_vm._v("Save Address")])])
}]

export { render, staticRenderFns }