<template>
  <div class="orderdetails">
    <Header></Header>
    <div class="breadcrumb-wrap bg-f br-1">
      <div class="overlay bg-black op-9"></div>
      <img :src="shape1" alt="Image" class="br-shape-1" />
      <img :src="shape2" alt="Image" class="br-shape-2" />
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="breadcrumb-title">
              <h2>Manage Address</h2>
              <ul class="breadcrumb-menu list-style">
                <li>
                  <!--<a href="index-2.html">Home </a>--><router-link
                    to="/"
                    style="color: white"
                    >Home</router-link
                  >
                </li>
                <li>Manage Address</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blog-details-wrap ptb-100">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-4 col-lg-12 order-xl-1 order-lg-2 order-md-2 order-2"
          >
            <div class="sidebar">
              <Leftsidebar></Leftsidebar>
            </div>
          </div>
          <div
            class="col-xl-8 col-lg-12 order-xl-2 order-lg-1 order-md-1 order-1"
          >
            <div class="bg-white mb-4">
              <h4 class="border-bottom pb-4">
                <i class="fa fa-pencil" aria-hidden="true"></i>
                Manage Address
              </h4>
              <div class="row mb-5">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <p class="heading-text">Save Address</p>
                    </div>
                    <div class="col-md-6 text-end mb-3">
                      <span
                        ><router-link to="/addaddress" class="btn style1"
                          >Add Address</router-link
                        ></span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="list-group">
                        <div v-for="(address, index) in addresses" :key="index">
                          <div
                            :class="
                              index % 2 == 0
                                ? `list-group-item list-group-item-action active`
                                : `list-group-item list-group-item-action`
                            "
                            :aria-current="index % 2 == 0 ? `true` : `false`"
                          >
                            <div class="d-flex w-100 justify-content-between">
                              <h5 class="mb-1 font-weight-bold">
                                {{ address.tag }}
                                <i
                                  :class="
                                    index % 2 == 0
                                      ? `fa fa-star text-warning ml-1`
                                      : `fa fa-star text-warning ml-1`
                                  "
                                  aria-hidden="true"
                                  v-if="
                                    userData.default_address_id == address.id
                                  "
                                ></i>
                              </h5>
                              <div>
                                <small
                                  style="
                                    font-size: 20px;
                                    position: relative;
                                    top: 14px;
                                    right: 14px;
                                  "
                                  :class="
                                    index % 2 == 0
                                      ? `text-dark mr-2`
                                      : `text-dark mr-2`
                                  "
                                  @click="delAddress(address.id)"
                                  ><i
                                    class="fa fa-trash-o"
                                    aria-hidden="true"
                                  ></i
                                ></small>
                                <small
                                  style="
                                    font-size: 20px;
                                    position: relative;
                                    top: 14px;
                                  "
                                  :class="
                                    index % 2 == 0
                                      ? `text-white`
                                      : `text-primary`
                                  "
                                  @click="defaultAddress(address.id)"
                                  v-if="
                                    userData.default_address_id != address.id
                                  "
                                >
                                  <i
                                    class="fa fa-star-o text-warning"
                                    aria-hidden="true"
                                  ></i>
                                </small>
                              </div>
                            </div>
                            <p class="mb-1">
                              {{ address.address }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/views/layouts/Header.vue";
import Leftsidebar from "@/views/sidebar/Leftsidebar.vue";
import Footer from "@/views/layouts/Footer.vue";
import { getLocalStorage, saveLocalStorage } from "@/store/service";
import {
  getAddresses,
  deleteAddress,
  setDefaultAddress,
  getUpdateInfo,
} from "@/store/api";
export default {
  props: {},
  name: "orderdetails",
  data() {
    return {
      shape1: require("@/assets/img/shape-1.png"),
      shape2: require("@/assets/img/shape-2.png"),
      addresses: [],
      userData: [],
      form: {
        address_name: "",
        address_id: 0,
      },
    };
  },
  components: {
    Header,
    Leftsidebar,
    Footer,
  },
  mounted() {
    this.getAddr();
    this.userDat();
  },
  methods: {
    userDat() {
      this.userData = getLocalStorage("userData");
    },
    getAddr() {
      getAddresses().then((res) => {
        console.log(res.data);
        this.addresses = res.data;
      });
    },
    defaultAddress(address) {
      this.form.address_id = address;
      setDefaultAddress(this.form)
        .then((res) => {
          console.log(res.data);
          getUpdateInfo().then((res) => {
            saveLocalStorage("userData", JSON.stringify(res.data.data));
            this.$toast.success("Default address selected successfully");
            this.userDat();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    delAddress(address) {
      this.form.address_id = address;
      deleteAddress(this.form).then((res) => {
        console.log(res.data);
        if (this.form.address_id === this.userData.default_address_id) {
          getUpdateInfo().then((res) => {
            saveLocalStorage("userData", JSON.stringify(res.data.data));
            this.userDat();
          });
        }
        this.$toast.success("Address deleted successfully");
        this.getAddr();
      });
    },
  },
};
</script>
<style>
ul.text-list li.text-link {
  list-style: none;
  font-size: 15px;
  line-height: 3;
}
ul.text-list {
  padding-left: 7px;
}
li.text-link i {
  color: #ffa323;
}
a.text-left {
  margin-left: 15px;
}
a.text-left.active {
  color: #ffa323;
  font-weight: 600 !important;
}
.heading-text {
  font-size: 18px;
  font-weight: 700;
  margin-top: 8px;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #f41f31;
  border-color: #e7e7e7;
}
h5.mb-1.font-weight-bold:active {
  color: white !important;
}
.list-group-item-action.active h5,
.list-group-item-action.active p,
.list-group-item-action.active .fa-trash-o {
  color: white !important;
}
.fa-trash-o:active {
  color: black;
}
</style>
